import React from "react";
import "./quotebox.css";
import axios from "axios";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTwitter, faTumblr } from "@fortawesome/free-brands-svg-icons";
import { faQuoteLeft, faClipboard } from "@fortawesome/free-solid-svg-icons";
import { CopyToClipboard } from "react-copy-to-clipboard";
import randomColor from "randomcolor";
import Helmet from "react-helmet";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function QuoteBox() {
  const [quote, setQuote] = useState("");
  const [author, setAuthor] = useState("");
  const [color, setColor] = useState("");
  const [key, setKey] = useState(Math.random());

  const fetchUrl = "https://api.quotable.io/random";

  async function fetchData() {
    const request = await axios.get(fetchUrl);
    setQuote(request.data.content);
    setAuthor(request.data.author);
    setColor(randomColor());
    return request;
  }

  useEffect(() => {
    fetchData();
  }, [key]);

  const changeKey = () => {
    setKey(Math.random());
    console.log("color = " + color);
  };

  return (
    <div key={key}>
      <Helmet bodyAttributes={{ style: `background-color : ${color}` }} />
      <div id="quote-box">
        <div className="quote-text" style={{ opacity: 1 }}>
          <FontAwesomeIcon icon={faQuoteLeft} />
          <span id="text"> {quote}</span>
        </div>
        <div className="quote-author" style={{ opacity: 1 }}>
          - <span id="author">{author}</span>
        </div>
        <div className="buttons">
          <a
            className="button"
            id="tweet-quote"
            title="Tweet this quote!"
            target="_blank"
            href={`https://twitter.com/intent/tweet?text=${quote}`}
            rel="noreferrer"
            style={{ backgroundColor: `${color}` }}
          >
            <FontAwesomeIcon icon={faTwitter} />
          </a>
          <a
            className="button"
            id="tumblr-quote"
            title="Post this quote on tumblr!"
            href="#!"
            style={{ backgroundColor: `${color}` }}
          >
            <FontAwesomeIcon icon={faTumblr} />
          </a>
          <CopyToClipboard
            onCopy={() => toast.success("🚀 Quote copied")}
            text={`"${quote}" by ${author}`}
          >
            <a
              className="button"
              id="copy-quote"
              title="Copy quote to clipboard"
              href="#!"
              style={{ backgroundColor: `${color}` }}
            >
              <FontAwesomeIcon icon={faClipboard} />
            </a>
          </CopyToClipboard>
          <ToastContainer
            position="top-right"
            autoClose={4000}
            draggable={false}
          />
          <button
            className="button"
            id="new-quote"
            style={{ backgroundColor: `${color}` }}
            onClick={changeKey}
          >
            New quote
          </button>
        </div>
      </div>
      <div className="footer">
        by <a href="#!">Umar</a>
      </div>
    </div>
  );
}

export default QuoteBox;
